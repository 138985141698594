import { sign } from 'node:crypto';
import { stringify } from 'qs';
import Hammer from 'hammerjs';
import process from 'process';

(window as any).dataLayer = (window as any).dataLayer || [];

type SignUpParams = { email: string; teamSize: string; name: string };

enum BillingTypes {
  YEAR = 'year',
  MONTH = 'month',
}

enum SubscriptionTypes {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
}

const TEAM_SIZES: string[] = ['1-10', '11-50', '51-100', '100+'];

const INDIVIDUAL_PRICE_MONTHLY: number = 3.99;
const INDIVIDUAL_PRICE_ANNUAL: number = 3.49;

const BUSINESS_PRICE_MONTHLY: number = 4.99;
const BUSINESS_PRICE_ANNUAL: number = 4.49;

const MONTHLY_PERIOD_TEXT: string = '/Mon';
const ANNUAL_PERIOD_TEXT: string = '/Mon';

const INDIVIDUAL_DISCOUNT: string = `${Math.floor(100 - (INDIVIDUAL_PRICE_ANNUAL * 100) / INDIVIDUAL_PRICE_MONTHLY)}%`;
const BUSINESS_DISCOUNT: string = `${Math.floor(100 - (BUSINESS_PRICE_ANNUAL * 100) / BUSINESS_PRICE_MONTHLY)}%`;
const DISCOUNT_TEXT: string = 'Save';

const CAMPAIGN_NAME: string = 'join';

function gtag(...args: unknown[]): void {
  (window as any).dataLayer.push(args);
}

gtag('js', new Date());

gtag('consent', 'default', {
  ad_personalization: 'denied',
  ad_user_data: 'denied',
  ad_storage: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'denied',
  personalization_storage: 'denied',
  security_storage: 'denied',
});

gtag('config', process.env.ENV === 'prod' ? 'G-4XTLXZDFXX' : 'G-FBF4ERS9QD');

const signUpUrl = (): string => {
  return `https://app${process.env.ENV_SUFFIX}.${process.env.DOMAIN_NAME}/auth/sign-up`;
};

const isEmail = (email: string): boolean => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
};

const hex = (value: string): string => {
  return [...value].reduce((acc: string, curr: string) => acc.concat(('000' + curr.charCodeAt(0).toString(16)).slice(-4)), '');
};

const setStorageItem = (key: string, value: unknown): void => window.localStorage.setItem(key, JSON.stringify(value));

const getStorageItem = <T>(key: string): T => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

const removeStorageItem = (key: string): void => window.localStorage.removeItem(key);

const price = (): void => {
  const monthly: Element = document.getElementById('monthly');
  const annual: Element = document.getElementById('annual');

  const individualPrice: HTMLElement = document.getElementById('individual-price');
  const individualPeriod: HTMLElement = document.getElementById('individual-period');
  const individualDiscount: HTMLElement = document.getElementById('individual-discount');
  const individualSelector: HTMLElement = document.getElementById('individual-selector');

  const businessPrice: HTMLElement = document.getElementById('business-price');
  const businessPeriod: HTMLElement = document.getElementById('business-period');
  const businessDiscount: HTMLElement = document.getElementById('business-discount');
  const businessSelector: HTMLElement = document.getElementById('business-selector');

  individualPeriod.innerText = MONTHLY_PERIOD_TEXT;
  businessPeriod.innerText = MONTHLY_PERIOD_TEXT;

  const makeMonthlyPriceActive = (): void => {
    monthly.classList.add('active');
    annual.classList.remove('active');

    individualPrice.innerText = INDIVIDUAL_PRICE_MONTHLY.toString();
    individualDiscount.classList.add('h-0', 'py-0');
    individualDiscount.classList.remove('h-[40px]', 'py-2');

    businessPrice.innerText = BUSINESS_PRICE_MONTHLY.toString();
    businessDiscount.classList.add('h-0', 'py-0');
    businessDiscount.classList.remove('h-[40px]', 'py-2');
  };

  const makeAnnualPriceActive = (): void => {
    annual.classList.add('active');
    monthly.classList.remove('active');

    individualPrice.innerText = INDIVIDUAL_PRICE_ANNUAL.toString();
    individualPeriod.innerText = ANNUAL_PERIOD_TEXT;
    individualDiscount.innerText = `${DISCOUNT_TEXT} ${INDIVIDUAL_DISCOUNT}`;
    individualDiscount.classList.remove('h-0', 'py-0');
    individualDiscount.classList.add('h-[40px]', 'py-2');

    businessPrice.innerText = BUSINESS_PRICE_ANNUAL.toString();
    businessPeriod.innerText = ANNUAL_PERIOD_TEXT;
    businessDiscount.innerText = `${DISCOUNT_TEXT} ${BUSINESS_DISCOUNT}`;
    businessDiscount.classList.remove('h-0', 'py-0');
    businessDiscount.classList.add('h-[40px]', 'py-2');
  };

  const redirectToSignUp = (event: PointerEvent): void => {
    const query: string = stringify({
      bt: hex(monthly.classList.contains('active') ? BillingTypes.MONTH : BillingTypes.YEAR),
      st: hex(individualSelector.isEqualNode(event.target as HTMLElement) ? SubscriptionTypes.INDIVIDUAL : SubscriptionTypes.BUSINESS),
      cn: hex(CAMPAIGN_NAME),
    });

    window.location.href = `${signUpUrl()}?${query}`;
  };

  if (!monthly.classList.contains('active') && !annual.classList.contains('active')) {
    makeMonthlyPriceActive();
  }

  monthly.addEventListener('click', makeMonthlyPriceActive);
  annual.addEventListener('click', makeAnnualPriceActive);

  individualSelector.addEventListener('click', redirectToSignUp);
  businessSelector.addEventListener('click', redirectToSignUp);
};

const footer = (): void => {
  document.getElementById('year').innerText = new Date().getFullYear().toString();
};

const signUp = (): void => {
  const email: Element = document.querySelector('input[name="email"]');

  email.addEventListener('keydown', () => {
    email.classList.remove('invalid');
    email.nextElementSibling.innerHTML = '';
  });

  const name: Element = document.querySelector('input[name="name"]');

  name.addEventListener('keydown', () => {
    name.classList.remove('invalid');
    name.nextElementSibling.innerHTML = '';
  });

  const teamSize: Element = document.querySelector('select[name="teamSize"]');

  teamSize.addEventListener('change', () => {
    teamSize.classList.remove('text-gray-dark-300', 'invalid');
    teamSize.classList.add('text-gray-dark-700');

    teamSize.parentElement.classList.remove('after:text-gray-dark-300');
    teamSize.parentElement.classList.add('after:text-gray-dark-700');

    teamSize.nextElementSibling.innerHTML = '';
  });

  document.querySelectorAll('a.conversion-link').forEach((conversionLink: Element) => {
    conversionLink.addEventListener('click', (event: Event) => {
      event.preventDefault();

      window.location.href = `${signUpUrl()}?${stringify({ cn: hex(CAMPAIGN_NAME) })}`;
    });
  });

  document.querySelector('form#sign-up').addEventListener('submit', (event: SubmitEvent) => {
    event.preventDefault();

    const signUpForm: FormData = new FormData(event.target as HTMLFormElement);
    const signUpParams: SignUpParams = Object.fromEntries(signUpForm) as SignUpParams;

    let emailInvalid: boolean = true;

    switch (true) {
      case !signUpParams?.email:
        email.nextElementSibling.innerHTML = 'Required field';
        break;
      case signUpParams?.email?.length > 256:
        email.nextElementSibling.innerHTML = 'Should be less than 256 symbols';
        break;
      case !isEmail(signUpParams?.email):
        email.nextElementSibling.innerHTML = 'Please, provide valid email';
        break;
      default:
        emailInvalid = false;
    }

    if (emailInvalid) {
      email.classList.add('invalid');
    }

    let nameInvalid: boolean = true;

    switch (true) {
      case !signUpParams?.name:
        name.nextElementSibling.innerHTML = 'Required field';
        break;
      case signUpParams?.name?.length > 256:
        name.nextElementSibling.innerHTML = 'Should be less than 256 symbols';
        break;
      default:
        nameInvalid = false;
    }

    if (nameInvalid) {
      name.classList.add('invalid');
    }

    let teamSizeInvalid: boolean = true;

    switch (true) {
      case !signUpParams?.teamSize:
        teamSize.nextElementSibling.innerHTML = 'Required field';
        break;
      case !TEAM_SIZES.includes(signUpParams.teamSize):
        teamSize.nextElementSibling.innerHTML = 'Please, provide valid team size';
        break;
      default:
        teamSizeInvalid = false;
    }

    if (teamSizeInvalid) {
      teamSize.classList.add('invalid');
    }

    if (emailInvalid || teamSizeInvalid || nameInvalid) {
      return;
    }

    if (process.env.MARKETING_API) {
      fetch(process.env.MARKETING_API, {
        method: 'POST',
        body: JSON.stringify({ email: signUpParams.email, teamSize: signUpParams.teamSize, name: signUpParams.name }),
      }).then();
    }

    window.location.href = `${signUpUrl()}?${stringify({ e: hex(signUpParams.email), cn: hex(CAMPAIGN_NAME) })}`;
  });
};

const header = (): void => {
  const desktopView = (): boolean => window.innerWidth > 475;

  const header: HTMLElement = document.getElementById('header');

  const toggleStickyHeader = () => {
    if (window.scrollY > header.clientHeight) {
      header.classList.add('bg-white', 'shadow-lg');
      header.classList.remove('bg-accent-dark');

      return;
    }

    header.classList.add('bg-accent-dark');
    header.classList.remove('bg-white', 'shadow-lg');
  };

  window.addEventListener('scroll', toggleStickyHeader);

  toggleStickyHeader();

  const sideMenuTriggers: NodeListOf<Element> = document.querySelectorAll('.side-menu-trigger');

  sideMenuTriggers.forEach((sideMenuTrigger: Element) => {
    sideMenuTrigger.addEventListener('click', () => {
      header.classList.toggle('active');
      document.body.classList.toggle('max-venus:overflow-hidden');
    });
  });

  const sideMenuLinks: NodeListOf<Element> = document.querySelectorAll('#side-menu a');

  sideMenuLinks.forEach((element: Element) => {
    element.addEventListener('click', () => {
      if (!desktopView()) {
        header.classList.remove('active');
        document.body.classList.remove('max-venus:overflow-hidden');
      }
    });
  });

  window.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event?.key?.toLowerCase() !== 'escape' || !header.classList.contains('active') || desktopView()) {
      return;
    }

    header.classList.toggle('active');
    document.body.classList.toggle('max-venus:overflow-hidden');
  });
};

const collapse = (): void => {
  const desktopView = (): boolean => window.innerWidth > 675;

  const collapses: HTMLCollectionOf<Element> = document.getElementsByClassName('peer/collapse');
  const slides: HTMLCollectionOf<Element> = document.getElementsByClassName('slide');
  const bullets: HTMLCollectionOf<Element> = document.getElementsByClassName('bullet');

  const toggleCollapse = (index: number = 0): void => {
    const newCollapse: Element = collapses.item(index);
    const newSlide: Element = slides.item(index);
    const newBullet: Element = bullets.item(index);

    if (newCollapse.classList.contains('active')) {
      return;
    }

    const currentCollapseIndex: number = Array.from(collapses).findIndex((collapse: Element) => collapse.classList.contains('active'));

    if (currentCollapseIndex >= 0) {
      const currentCollapse: Element = collapses.item(currentCollapseIndex);

      currentCollapse.nextElementSibling.classList.add('delay-[400ms]');
      currentCollapse.classList.remove('active');

      const currentSlide: Element = slides.item(currentCollapseIndex);

      currentSlide.classList.add('duration-0');
      currentSlide.classList.remove('duration-[500ms]', 'active');

      const currentBullet: Element = bullets.item(currentCollapseIndex);
      currentBullet.classList.remove('active');
    }

    newCollapse.nextElementSibling.classList.remove('delay-[400ms]');
    newCollapse.classList.add('active');

    newSlide.classList.remove('duration-0');
    newSlide.classList.add('duration-[500ms]', 'active');

    newBullet.classList.add('active');
  };

  Array.from(slides).forEach((slide: HTMLElement) => {
    const hammer: HammerManager = new Hammer(slide);

    hammer.on('swiperight', (event: HammerInput) => {
      if (!event.isFinal || desktopView()) {
        return;
      }

      const index: number = Array.from(collapses).findIndex((collapse: Element) => collapse.classList.contains('active'));

      toggleCollapse(!index ? collapses.length - 1 : index - 1);
    });

    hammer.on('swipeleft', (event: HammerInput) => {
      if (!event.isFinal || desktopView()) {
        return;
      }

      const index: number = Array.from(collapses).findIndex((collapse: Element) => collapse.classList.contains('active'));

      toggleCollapse(index + 1 >= collapses.length ? 0 : index + 1);
    });
  });

  Array.from(collapses).forEach((collapse: HTMLElement, index: number) => {
    collapse.addEventListener('click', () => {
      toggleCollapse(index);
    });

    collapse.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event?.key?.toLowerCase() !== 'enter') {
        return;
      }

      toggleCollapse(index);
    });
  });

  Array.from(bullets).forEach((collapse: HTMLElement, index: number) => {
    collapse.addEventListener('click', () => {
      toggleCollapse(index);
    });
  });
};

const googleTag = (): void => {
  let consentPreferences: Record<string, string> = {};

  const cookieBanner: HTMLElement = document.getElementById('cookie-banner');

  const showCookieBanner = (): void => {
    if (cookieBanner.classList.contains('hidden')) {
      cookieBanner.classList.remove('hidden');
    }
  };

  const hideCookieBanner = (): void => {
    if (!cookieBanner.classList.contains('hidden')) {
      cookieBanner.classList.add('hidden');
    }
  };

  const allConsentGranted = (): void => {
    consentPreferences = {
      ad_personalization: 'granted',
      ad_user_data: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted',
    };

    gtag('consent', 'update', consentPreferences);

    setStorageItem('consent-allowed', true);
    setStorageItem('consent-preferences', consentPreferences);

    hideCookieBanner();
  };

  const allConsentDenied = (): void => {
    gtag('consent', 'update', {
      ad_personalization: 'denied',
      ad_user_data: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'denied',
    });

    setStorageItem('consent-allowed', false);
    removeStorageItem('consent-preferences');

    hideCookieBanner();
  };

  document.getElementById('accept-all-cookie').addEventListener('click', allConsentGranted);

  document.getElementById('reject-all-cookie').addEventListener('click', allConsentDenied);

  document.querySelectorAll('.conversion-link').forEach((conversionLink: Element) => {
    conversionLink.addEventListener('click', () => {
      if (!consentPreferences?.analytics_storage) {
        return;
      }

      gtag('event', 'app_visit');
    });
  });

  const consentAllowed: boolean = getStorageItem('consent-allowed');

  if (typeof consentAllowed !== 'boolean') {
    return showCookieBanner();
  }

  consentPreferences = getStorageItem('consent-preferences');

  if (consentAllowed) {
    return gtag('consent', 'update', consentPreferences);
  }
};

window.addEventListener('load', (): void => {
  header();
  footer();
  price();
  signUp();
  collapse();
  googleTag();
});
